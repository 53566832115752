import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

const axios = require('axios');

async function getMinecraftIp() {
  const response = await axios({
    method: "post",
    url: "https://prod-49.northeurope.logic.azure.com:443/workflows/959c91a3b9034a5ab563ad9b3dc9d36f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RszeGd90EaXU2YJd3XIj_2ZdyWRZsE-7z00pVQahWDE",
    headers: {'ContentType': 'application/json'},
    data: {}
  });

  var currentStatus = response.data["result"] === 'true';
  if (currentStatus === false) {
    return null;
  } else {
    var ip = response.data["ip"];
    return ip;
  } 
}

class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyStarted: true,
      buttonText: "Checking status..."
    };
  }

  async click() {
    this.setState({ alreadyStarted: true, buttonText: "Fetching IP..." });

    await axios({
        method: "post",
        url: "https://prod-18.northeurope.logic.azure.com:443/workflows/cdcb54ccc280442b81cc5340198ad4cc/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Vz4d1i3KBTFCYDUSwXWft285lCnjVGbGi3QheYfkr-o",
        headers: {'ContentType': 'application/json'},
        data: {}
    }).then(() => console.log("Success on start server post request!")).catch(() => console.log("Error on start request!"));
    
    const ip = await getMinecraftIp();
    if (ip === null) {
      this.setState({ alreadyStarted: true, buttonText: "Weird.. started but no ip..." });
    } else {
      this.setState({ alreadyStarted: true, buttonText: ip });
    }     
  }

  async componentDidMount() {
    const ip = await getMinecraftIp();
    if (ip === null) {
      this.setState({ alreadyStarted: false, buttonText: "Start server" });
    } else {
      this.setState({ alreadyStarted: true, buttonText: ip });
    } 
  }

  render() {
    return (
      <div style={{ padding: "20px 0px 20px 0px" }}>
        <button onClick={this.click.bind(this)} disabled={this.state.alreadyStarted}>{ this.state.buttonText }</button>
      </div>
    );
  }
}

export default () => {
  return (
    <Layout>
      <div
        style={{
          backgroundColor: "#E1E1E1",
          padding: "20px",
          margin: "10px",
          borderStyle: "solid",
          borderWidth: "0px"
        }}
      >
        <h2
          dangerouslySetInnerHTML={{ __html: "Start Minecraft server" }}
          style={{ margin: "0px 0px 10px 0px" }}
        />
        <ButtonComponent></ButtonComponent>
        <Link to={"/"}>Back home</Link>
      </div>
    </Layout>
  );
};
